// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "bootstrap-datetimepicker.min";


body{
  background-color: #f5f5f5;
}
img{
  max-width: 100%;
}

main{
  margin-top: 50px;
}

ul{
  list-style-type: none;
}

.users-manage-panel form{
  display: inline-block;
}

.users-manage-panel .btn-container{
  float: right;
}

.list-group-item{
  padding-top: 15px;
  padding-bottom: 15px;
}

.list-group--partners{
  
  .btn{
    margin-right: 10px;
  }

}

#form-add-partners{
  .access-token-input,.access-url-input{
    width: 80%; 
    margin-right: 20px;
    float: left;
  }

  .access-token-container, .access-url-container{
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fa-plus-circle{
    font-size: 28px;
    color: #5CB860;
  }

  .fa-minus-circle{
    font-size: 22px;
    color: #d9534f;
  }
}

th.fields { max-width: 400px; }

.form-group { position: relative; }